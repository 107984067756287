import React from 'react';

export const Frame = () => {
  const arr = window.location.pathname.split('/');
  arr.splice(-1, 1);
  const url = window.location.origin + arr.concat(['frame-test.html']).join('/');
  return (
    <div>
      <h1>Frame Example</h1>
      <frameset className='pt-4' rows='131,*,43'>
        <frame src={url} />
      </frameset>
    </div>
  );
};
