import React, { Fragment } from 'react';
import './direction.css';
import { Link } from 'react-router-dom';
import { Card } from '../../Components/Card';

const cardList = [
  {
    title: 'Multi-page',
    description: 'Muti-page application for test out WOGAA.js.',
    link: 'multipage-index.html',
    linkType: 'external',
  },
  {
    title: 'Demo Web app',
    description: 'React base single page app to test out WOGAA.js.',
    link: '/sign',
  },
  {
    title: 'WOGAA Layer Helper',
    description: <>Demo <b>experimental</b> WOGAA Layer message subscriber.</>,
    link: '/wogaaLayer',
  },
  {
    title: 'Frame Example (Deprecated)',
    description: <>You can <b>FRAME</b> a moment. But you can't <b>FRAME</b> life.</>,
    link: '/frame',
  },
  {
    title: 'Hot Keys',
    description: 'Test ground for hot keys',
    link: 'hotkeys.html',
    linkType: 'external',
  },
  {
    title: 'Image rendering',
    description: <img
      src='https://mytax.iras.gov.sg/ESVWeb/MBCommon/Images/icon-info-tooltip.png'
      height='19'
      alt='random-image'
    />,
    link: '/#',
  },
  {
    title: 'Double events fire on history push',
    description: `Test ground for double firing of events when history.pushState and history.replaceState trigger at same time.`,
    link: 'history.html',
    linkType: 'external',
  },
  {
    title: 'Content Privacy Policy',
    description: 'Test ground for content privacy policy.',
    link: 'csp.html',
    linkType: 'external',
  },
  {
    title: 'Insert WOGAA.js through Javascript',
    description: 'Test ground for injecting wogaa.js instead of placing script tag manually.',
    link: 'script-insert.html',
    linkType: 'external',
  },
  {
    title: 'Duplicate WOGAA JS',
    description: 'What will happen when you add multiple wogaa.js in the same page?',
    link: 'duplicate-wogaa-js.html',
    linkType: 'external',
  },
  {
    title: 'Transactional Service Test #3',
    description: 'Test with start and end with a page in the middle.',
    link: 'test3-page1.html',
    linkType: 'external',
  },
  {
    title: 'Transactional Service Test #4',
    description: 'Test with start and end with a 2 transactional services crossing each other.',
    link: 'test4-page1.html',
    linkType: 'external',
  },
  {
    title: 'Transactional Service Test #5',
    description: 'Test with start multiple and end together.',
    link: 'test5-page1.html',
    linkType: 'external',
  },
  {
    title: 'Transactional Service Test #6',
    description: 'Start together and end separate.',
    link: 'test6-page1.html',
    linkType: 'external',
  },
  {
    title: 'Search SG Test',
    description: 'SearchSG Config JS loading test',
    link: 'searchsg.html',
    linkType: 'external',
  },
];

export const Direction = () => {
  const customNavigation = (name) => {
    const arr = window.location.pathname.split('/');
    arr.splice(-1, 1);
    window.location.href =
      window.location.origin + arr.concat([name]).join('/');
  };

  return (
    <Fragment>
      <div style={{
        height: 40,
        backgroundColor: '#1e1e1e',
        color: 'white',
        textAlign: 'center',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
          ✨ Try out the new Playground <Link className='ml-1'
            to=''
            onClick={() => customNavigation('modern.html')}>here</Link>.
        </div>
      </div>
      <div className='container-flex'>
        <div className='d-flex justify-content-center pt-5 pb-5 mb-0'>
          <h1
            style={{
              position: 'absolute',
            }}>WOGAA.JS
          </h1>
        </div>
        <div className='color-grad' />
      </div>
      <div className='container mx-auto my-5'>
        <div className='container-grid'>
          {cardList.map((card) => <Card key={card.title} {...card} />)}
        </div>
      </div>
    </Fragment>
  );
};
