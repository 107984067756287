import React from 'react';

import { useNavigate } from 'react-router-dom';
import image from '../../images/cole-searching.png';
import './sign-in.css';

export const SignIn = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.wogaaCustom.startTransactionalService(process.env.REACT_APP_TRACKING_ID_TS_1);
    navigate('/home');
  };

  return (
    <div className='text-center'>
      <form className='form-signin'>
        <img
          className='mb-4'
          src={image}
          alt='random-sign-in-image'
          width='250'
        />
        <h1 className='h3 mb-3 font-weight-normal'>Please sign in</h1>
        <label htmlFor='inputEmail' className='sr-only'>
          Email address
        </label>
        <input
          type='email'
          id='inputEmail'
          className='form-control'
          placeholder='Email address'
          autoFocus
        />
        <label htmlFor='inputPassword' className='sr-only'>
          Password
        </label>
        <input type='password' id='inputPassword' className='form-control' placeholder='Password' />
        <div className='checkbox mb-3'>
          <label>
            <input type='checkbox' value='remember-me' /> Remember me
          </label>
        </div>
        <button
          className='btn btn-lg btn-primary btn-block'
          type='button'
          onClick={handleClick.bind(this)}
        >
          Sign in
        </button>
        <p className='mt-5 mb-3 text-muted'>&copy; 2017-2019</p>
      </form>
    </div>
  );
};
