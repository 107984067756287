import { Link } from 'react-router-dom';
import React from 'react';

export const Card = ({ title, description, link, linkType }) => {

  const customNavigation = (name) => {
    const arr = window.location.pathname.split('/');
    arr.splice(-1, 1);
    window.location.href =
      window.location.origin + arr.concat([name]).join('/');
  };

  if (linkType === 'external') {
    return (
      <div>
        <div className='card shadow-sm'>
          <div className='card-body'>
            <h5 className='card-title'>{title}</h5>
            <p className='card-text'>{description}</p>
            <Link
              to=''
              onClick={() => customNavigation(link)}
              className='card-link'
            >
              Take this path
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='card shadow-sm'>
        <div className='card-body'>
          <h5 className='card-title'>{title}</h5>
          <p className='card-text'>{description}</p>
          <Link to={link} className='card-link'>
            Take this path
          </Link>
        </div>
      </div>
    </div>
  );
};
