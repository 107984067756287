import React, { Component } from 'react';
import ReactJson from 'react-json-view';
import WogaaLayerHelper from 'wogaa-layer-helper';

class Subscriber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: [],
    };
  }

  componentWillMount() {
    new WogaaLayerHelper(window.wogaaLayer, (model, payload) => {
      setTimeout((_) => {
        this.setState({
          payload: [...this.state.payload, payload],
        });
      });
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{this.props.name}</h5>
          <ReactJson
            name={false}
            iconStyle={'circle'}
            indentWidth={2}
            enableClipboard={false}
            displayObjectSize={false}
            displayDataTypes={false}
            src={this.state.payload}
          />
        </div>
      </div>
    );
  }
}

export default Subscriber;
