import React, { Fragment } from 'react';
import Header from '../../Components/header/header';
import { Link } from 'react-router-dom';

export const About = () => {
  return (
    <Fragment>
      <Header />
      <h1>About</h1>

      <Link to='/home'>Back</Link>
    </Fragment>
  );
};
