import React, { Fragment, useCallback, useState } from 'react';
import Header from '../../Components/header/header';
import { Link, useNavigate } from 'react-router-dom';

import './home.css';

export const Home = () => {
  const navigate = useNavigate();
  const [txnId1] = useState(window?.wogaaCustom?.siteInfo?.txnSvcs[0] || 1);
  const [txnId2] = useState(window?.wogaaCustom?.siteInfo?.txnSvcs[1] || 2);

  const handleTest1Click = useCallback(() => {
    window.wogaaCustom.completeTransactionalService(txnId1);
    navigate('/about');
  }, [txnId1]);

  const handleTest2Click = useCallback(() => {
    window.wogaaCustom.startTransactionalService(txnId2);
  }, [txnId2]);

  const handleTest2CompleteClick = useCallback(() => {
    window.wogaaCustom.completeTransactionalService(txnId2);
  }, [txnId2]);

  return (
    <Fragment>
      <Header />
      <div className='container'>
        <h1>Control Panel</h1>

        <div className='wog-grid'>
          <div className='wog-flex wog-flex-col wog-card'>
            <p>Transactional Service: {txnId1}</p>
            <div>
              <button className='btn btn-primary' onClick={handleTest1Click.bind(this)}>
                Start
              </button>
            </div>
          </div>
          <div className='wog-flex wog-flex-col wog-card'>
            <p>Transactional Service: {txnId2}</p>
            <div className='wog-flex'>
              <button className='btn btn-primary' onClick={handleTest2Click.bind(this)}>
                Start TS
              </button>
              <button className='btn btn-primary' onClick={handleTest2CompleteClick.bind(this)}>
                Complete TS
              </button>
            </div>
          </div>
          <div className='wog-flex wog-flex-col wog-card'>
            <p>Navigation</p>
            <div className='wog-flex'>
              <Link to='/sign' className='btn btn-primary'>
                Sign-In Page
              </Link>
              <Link to='/about' className='btn btn-primary'>
                About Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
