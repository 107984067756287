import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { SignIn } from './Pages/SignIn';
import { Home } from './Pages/Home';
import { About } from './Pages/About';
import { Direction } from './Pages/Direction';
import { WogaaLayer } from './Services/wogaa-layer';
import { Frame } from './Pages/Frame';

import './App.css';

function App() {
  return (
    <>
      <div id='searchsg-searchbar'></div>
      <HashRouter>
        <Routes>
          <Route path='/' element={<Direction />} />
          <Route path='/wogaaLayer' element={<WogaaLayer />} />
          <Route path='/frame' element={<Frame />} />
          <Route path='/sign' element={<SignIn />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
