import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import Subscriber from './subscriber';

export const WogaaLayer = () => {
  const [wogaaEvent, setWogaaEvent] = useState({
    event: 'START_TS',
    trackingId: 'hdb-165',
  });

  const [dataLayer, setDataLayer] = useState([]);
  const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    window.wogaaLayer = [];
  }, []);

  const handleEdit = (d) => {
    setWogaaEvent(d.updated_src);
  };

  const handleAdd = (d) => {
    setWogaaEvent(d.updated_src);
  };

  const handleDispatchEvent = () => {
    window.wogaaLayer.push({
      ...wogaaEvent,
      time: new Date().getTime(),
    });
    setDataLayer(window.wogaaLayer.map((d) => d));
  };

  const handleAddSubscriber = () => {
    setSubscribers([...subscribers, { sub: 1 }]);
  };

  const renderSubscribers = (subs) => {
    return subs.map((d, i) => <Subscriber key={i} name={`Subscriber ${i + 1}`} />);
  };

  return (
    <div>
      <div className='container-fluid'>
        <div className='py-3'>
          <h1>WOGAA LAYER HELPER!!!</h1>
          <p>
            This library provides the ability to process messages passed onto a wogaaLayer queue.
          </p>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <div className='card'>
              <div className='card-body'>
                <h5 className='card-title'>WOGAA Layer</h5>
                <p className='card-text'>
                  Each new message will be merged into the helper's "abstract data model". This
                  internal model object holds the most recent value for all keys which have been
                  set on messages processed by the helper.
                </p>
                <hr />
                <ReactJson
                  name={false}
                  iconStyle={'circle'}
                  indentWidth={2}
                  enableClipboard={false}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  src={dataLayer}
                />
              </div>
            </div>
          </div>
          <div className='col-md-8'>
            <div className='row'>
              <div className='col-md-12 mb-4'>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title'>Controls</h5>
                    <p className='card-text'>
                      Dispatch events to <b>WOGAA Layer</b>
                    </p>
                    <ReactJson
                      name={false}
                      iconStyle={'circle'}
                      indentWidth={2}
                      onEdit={handleEdit}
                      onAdd={handleAdd}
                      enableClipboard={false}
                      displayObjectSize={false}
                      displayDataTypes={false}
                      src={wogaaEvent}
                    />
                    <hr />
                    <button
                      className='btn btn-primary mx-2'
                      onClick={handleDispatchEvent}>
                      Dispatch Event
                    </button>
                    <button
                      className='btn btn-dark mx-2'
                      onClick={handleAddSubscriber}>Add Subscriber
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title'>Subscribers</h5>
                    <p className='card-text'>All the subscriptions</p>
                    <div className='card-deck'>
                      {renderSubscribers(subscribers)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
